import loadjs from 'load-js';
import LT_ENV from '../common/lt-env';
import { LaunchReady } from '../integrations/launch-tracking';

declare global {
  /* eslint-disable-next-line no-unused-vars */
  interface Window {
    disablelaunch?: boolean;
    /* eslint-disable-next-line camelcase */
    launch_key?: string;
    ltanalytics?: any;
  }
}

// prod value does not need flag, used as fallback
const envFlag =
  {
    preprod: '-staging',
    stage: '-staging',
    dev: '-development',
    local: '-development',
  }[LT_ENV] || '';

export default () => {
  if (window.disablelaunch) return;
  if (!window.launch_key) return;
  const launchKey: string = window.launch_key?.startsWith('launch-')
    ? `${window.launch_key}${envFlag}`
    : `launch-${window.launch_key}${envFlag}`;
  loadjs([
    { url: `https://assets.adobedtm.com/${launchKey}.min.js`, async: true },
  ])
    .then(() => {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_loaded"] }] */
      if (!window.ltanalytics._loaded) {
        window.ltanalytics._loaded = false;
        console.error(
          'Launch did not validate loading with ltanalytics._loaded == true',
        );
      }
    })
    .catch((e) => {
      window.ltanalytics._loaded = false;
      console.error('ERROR Loading Adobe Launch', e);
    })
    .finally(LaunchReady);
};
