export const lowercaseKeyAndPick = (
  iter: { [x: string]: any },
  keys: string[],
) => {
  const obj = {};
  for (const [key, val] of iter as string[]) {
    const newKey = key.toLowerCase();
    if (keys.includes(newKey)) obj[newKey] = val;
  }
  return obj;
};
