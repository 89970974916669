import setSafeFunction from './includes/handler/setsafefunction';
import disableanalytics from './includes/integrations/disableanalytics';
import ltanalytics from './includes/integrations/ltanalytics-lta-launchstrap';
import adobelaunch from './includes/integrations/lta-onpageload-adobelaunch';
import referralPixel from './includes/integrations/referral-pixel';
import setAshData from './includes/integrations/lta-ash-data';
import onPageloadGtm from './includes/integrations/lta-onpageload-gtm';

const functionsArray = [
  disableanalytics,
  ltanalytics,
  referralPixel,
  setAshData,
  adobelaunch,
  onPageloadGtm,
];

for (const i in functionsArray) {
  const safeFunction = setSafeFunction(functionsArray[i]);
  safeFunction();
}
