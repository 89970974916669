import LT_ENV from '../common/lt-env';
import { getCookie, setCookie } from '../common/get-cookie';

import { getCookieObject } from '../handler/mkttrack-cookie-handler';

// common includes and handler

const neustarDataCookiename = 'Neustar_MKT_Track';
const isNeustarMKTTrack = (): boolean => !!getCookie(neustarDataCookiename);

export const neustarUrl: string =
  {
    prod: 'https://c360neustarapp.lendingtree.com',
    preprod: 'https://c360neustarapp.preprod.lendingtree.com',
    stage: ' https://c360neustarapp.staging.lendingtree.com',
  }[LT_ENV] || ' https://c360neustarapp.dev.lendingtree.com';

export const neuStarCliendID: string =
  {
    prod: 'dfa94b38-ee6f-41e6-816f-d6f79f9f0442',
    preprod: '272cd457-1c4d-454b-b6fc-c11290a3e396',
    stage: '272cd457-1c4d-454b-b6fc-c11290a3e396',
  }[LT_ENV] || '272cd457-1c4d-454b-b6fc-c11290a3e396';

export const setNeustarCookie = async (): Promise<void> => {
  const mktTrackCookie = getCookieObject();
  const ipReponse = await fetch('https://api.ipify.org?format=json');
  const res = await ipReponse.json();
  const ip4 = res.ip;
  const raw = JSON.stringify({
    clientId: neuStarCliendID,
    tracking: {
      sessionId: mktTrackCookie?.ClickSessionId,
    },
    fabrickRequest: {
      i4: ip4,
      '1pd': mktTrackCookie?.AnonymousId,
    },
  });
  fetch(`${neustarUrl}/api/fabrick`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    keepalive: true,
    body: raw,
  })
    .then((res) => res.json())
    .then((resultData) => {
      const fabResult = encodeURIComponent(
        JSON.stringify(resultData?.result?.fabrickResult),
      );
      setCookie(neustarDataCookiename, fabResult, 7);
    });
};
export default (): void => {
  if (!isNeustarMKTTrack() && window.location.host.includes('lendingtree.com'))
    setNeustarCookie();
};
