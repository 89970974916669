import {
  getParamsFromQueryString,
  getParamByName,
} from '../handler/marketing-tracking-handler';
import { getData } from './lta-data';
import { memoize } from '../common/memoize';
import { isTrackingReady } from './lta-ash-data';
import { isUndefined } from '../common/is-type';

declare const window: any;
// common includes

/**
 * @param {*} propertyObj the properties to be evaluated
 * @param {string} propType description of what kind of properties are being passed in
 */

const setTrackProps = memoize((isTrackingReady: boolean) => {
  let trackProperties = {};
  try {
    const trackObj = getData();

    if (isUndefined(trackObj) || isUndefined(trackObj.esourceid)) {
      const qsObj: any = getParamsFromQueryString();
      if (qsObj.esourceid) {
        Object.assign(trackObj, qsObj);
      }
    }

    if (trackObj.AnonymousId) {
      delete trackObj.AnonymousId;
    }

    if (trackObj.timestamp) {
      delete trackObj.timestamp;
    }

    trackProperties = {
      isTrackingReady,
      ...trackObj,
    };
  } catch (e) {
    console.error(e);
  }
  return trackProperties;
});

export const buildTrackProperties = (propertyObj: Object = {}) => ({
  ...propertyObj,
  ...setTrackProps(isTrackingReady),
});

export default () => {
  const ltanalytics = (window.ltanalytics = window.ltanalytics || []);
  ltanalytics.methods = [
    'getAndApplyOffers',
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'push',
    'once',
    'off',
    'on',
    'postToKafka',
  ];

  ltanalytics.factory = (method) =>
    function () {
      const args: any = [].slice.call(arguments);
      args.unshift(method);
      ltanalytics[ltanalytics.length] = args;
      return ltanalytics;
    };

  for (let i = 0; i < ltanalytics.methods.length; i++) {
    const key = ltanalytics.methods[i];
    ltanalytics[key] = ltanalytics.factory(key);
  }

  window.ltanalytics = Object.assign(window.ltanalytics, {
    getParameterByName: getParamByName,
    getTrackProperties: buildTrackProperties,
  });
};
