import LT_ENV from '../common/lt-env';
import domainName from '../common/root-domain-name';
import { getCookie } from '../common/get-cookie';
import { isUndefined } from '../common/is-type';
import { setData } from './lta-data';
import C360neustarData from './C360neustar-data';

import {
  getMktTrackDataFromApi,
  getCookieObject,
  getParamFromQs,
} from '../handler/mkttrack-cookie-handler';
import {
  getMarketingDataFromApi,
  getParamsFromCookie,
} from '../handler/marketing-tracking-handler';
import producttracking from '../handler/product-tracking-handler';

// common includes and handler

const referrerUrl = document.referrer;
const referrerUrlHost = referrerUrl
  ? new URL(document.referrer).hostname
  : null;
const qsObj: { [k: string]: any } = Object.fromEntries(
  new URLSearchParams(location.search),
);
const mktTrackCookieName = 'MKT_TRACK';
const marketingCookieName = 'LT_MKT_TRACK';
const userOriginationDataCookieName = 'UserOriginationData';
let mktTrackCookieObj: any = null;
export let isTrackingReady: boolean = false;

export const hasNoValidSession = (): boolean => {
  const getMktTrackCookieByName = getCookie(mktTrackCookieName);
  if (getMktTrackCookieByName) {
    mktTrackCookieObj = JSON.parse(decodeURIComponent(getMktTrackCookieByName));
  }

  if (!mktTrackCookieObj) {
    return true;
  }

  if (
    referrerUrl &&
    referrerUrlHost &&
    referrerUrlHost.indexOf(domainName()) === -1
  ) {
    return true;
  }

  if (
    mktTrackCookieObj &&
    qsObj.mtaid &&
    qsObj.mtaid.toLowerCase() !== mktTrackCookieObj.mtaid.toLowerCase()
  )
    return true;

  if (mktTrackCookieObj && mktTrackCookieObj.timestamp) {
    const oneDay = 24 * 60 * 60 * 1000;
    const cookieTimestamp: any = new Date(mktTrackCookieObj.timestamp);
    const utcTimestampString = new Date().toISOString();
    const currentUtcTimestamp: any = new Date(utcTimestampString);

    return currentUtcTimestamp - cookieTimestamp > oneDay;
  }

  // DO NOT call API if above conditions are false
  return !isTrackingReady;
};

export const getMTAIDSource = (objType: any): { [k: string]: string } => {
  let MTAIDSource = '';
  const trackingObj: { [k: string]: any } = getCookieObject();
  if (objType === 'API') {
    if (
      (!isUndefined(trackingObj && trackingObj.mtaid) &&
        trackingObj.mtaid === getParamFromQs('mtaid')) ||
      getParamFromQs('mtaid')
    )
      MTAIDSource = 'URL';
    else MTAIDSource = 'API';
  } else
    MTAIDSource = !isUndefined(trackingObj && trackingObj.mtaid)
      ? 'Cookie'
      : '';

  return {
    MTAIDSource,
  };
};

export const emitTrackingReady = (responseData: string[]): void => {
  if (responseData && responseData.length > 0) {
    const dataObj: { [k: string]: string } = responseData.reduce(
      (cookieData, currVal) => {
        if (currVal.startsWith(mktTrackCookieName)) {
          Object.assign(cookieData, getMktTrackDataFromApi(currVal));
        } else if (currVal.startsWith(marketingCookieName)) {
          Object.assign(cookieData, getMarketingDataFromApi(currVal));
        } else if (currVal.startsWith(userOriginationDataCookieName)) {
          Object.assign(
            cookieData,
            getMktTrackDataFromApi(currVal, userOriginationDataCookieName),
          );
        }
        return cookieData;
      },
      {},
    );

    if (Object.keys(dataObj).length > 0) {
      Object.assign(dataObj, producttracking.get());
      setData(dataObj);
    }

    if (Object.keys(dataObj).length > 0) {
      const mtaidSource = dataObj.mtaid ? getMTAIDSource('API') : {};
      Object.assign(dataObj, mtaidSource);
      setData(dataObj);
    }
  } else {
    const trackProperties = {
      ...getParamsFromCookie(),
      ...getCookieObject(),
      ...producttracking.get(),
      ...getCookieObject(userOriginationDataCookieName),
      ...getMTAIDSource(null),
    };
    setData(trackProperties);
  }
  C360neustarData();
  const event = new CustomEvent('TRACKING_READY');
  document.documentElement.dispatchEvent(event);
  isTrackingReady = true;
};

export const splitterHost: string =
  {
    prod: 'splitter.lendingtree.com',
    preprod: 'splitter.preprod.lendingtree.com',
    stage: 'splitter.staging.lendingtree.com',
  }[LT_ENV] || 'splitter.dev.lendingtree.com';

export default (): void => {
  if (hasNoValidSession()) {
    const crossDomainUrl: URL = new URL(
      `https://${splitterHost}/api/general/details`,
    );
    crossDomainUrl.searchParams.set('originalReferrer', document.referrer);
    crossDomainUrl.searchParams.set('originalURL', document.URL);
    fetch(crossDomainUrl.toString(), {
      credentials: 'include',
      keepalive: true,
    })
      .then((res) => res.json())
      .then(({ cookies }) => {
        const domain = location.hostname;
        const cookieDomain = domain.startsWith('www')
          ? domain.split('www.')[1]
          : domain;
        // Optionally log cookies before processing
        // Check if the domain is not 'lendingtree.com'
        if (!domain.endsWith('lendingtree.com')) {
          // Set cookies based on the domain
          for (const cookieStr of cookies) {
            // Extract the domain attribute from each cookie string if needed
            // Note: This is a simple approach. Adjust the parsing as needed based on your cookie format
            document.cookie = `${cookieStr};domain=.${cookieDomain}`;
            // Optionally log each cookie being set
          }
        }
        emitTrackingReady(cookies);
      })
      .catch((e) => {
        console.error(e);
        emitTrackingReady([]);
      });
  } else {
    emitTrackingReady([]);
  }
};
