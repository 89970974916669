import { isTrackingReady } from '../integrations/lta-ash-data';

export default (fn: () => void) => {
  if (isTrackingReady) {
    fn();
  } else {
    document.documentElement.addEventListener('TRACKING_READY', () => {
      fn();
    });
  }
};
