// Taken from: http://bonsaiden.github.io/JavaScript-Garden/#types.typeof

function is(type: string, obj: any) {
  return Object.prototype.toString.call(obj).slice(8, -1) === type;
}

export function isArray(primitive: any) {
  return is('Array', primitive);
}
export function isObject(primitive: any) {
  return is('Object', primitive);
}
export function isString(primitive: any) {
  return is('String', primitive);
}
export function isFunction(primitive: any) {
  return is('Function', primitive);
}
export function isNumber(primitive: any) {
  return is('Number', primitive);
}
export function isBoolean(primitive: any) {
  return is('Boolean', primitive);
}
export function isUndefined(primitive: any) {
  return is('Undefined', primitive);
}
export function isNull(primitive: any) {
  return is('Null', primitive);
}
