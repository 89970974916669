import LT_ENV from '../common/lt-env';
import { getCookie } from '../common/get-cookie';
import domainName from '../common/root-domain-name';
import { getClickSessionId } from '../handler/mkttrack-cookie-handler';

// common includes and handler

const aamCookieName = 'aam_lt';
const uuidCookieName = 'uuid_lt';
let visitorId: any;
declare const window: any;

export const buildReferralPixelTrackingProperties = (
  eventName: string,
): { [k: string]: string } => {
  const deviceType = /iPad/.test(navigator.userAgent)
    ? 'iPad'
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent,
      )
    ? 'mobile'
    : 'desktop';

  let propertyObj: any = {
    domain: domainName(),
    referrer: document.referrer,
    device: deviceType,
    eventname: eventName,
    type: 'Analytics Bootstrap',
  };

  if (window.Visitor) {
    const visitor = window.Visitor.getInstance(
      '2E96401053DB518E0A490D4C@AdobeOrg',
    );
    propertyObj.adobevisitorid =
      visitorId || visitor.getMarketingCloudVisitorID();
  }

  propertyObj.clicksessionid = getClickSessionId();

  if (eventName === 'ltanalytics_page_fires') {
    const getURLSearchParams = new URLSearchParams(getCookie(aamCookieName));
    let aamCookieValue: any = Object.fromEntries(getURLSearchParams);
    const uuidCookieData = getCookie(uuidCookieName) || '';
    if (aamCookieValue) aamCookieValue = aamCookieValue.aam_lt || '';
    propertyObj = Object.assign(propertyObj, {
      aamid: aamCookieValue,
      uuid: uuidCookieData,
    });
  }
  return propertyObj || {};
};

window.buildReferralPixelTrackingProperties =
  buildReferralPixelTrackingProperties;

export const pixelHostname =
  {
    prod: 'www.lendingtree.com',
    preprod: 'www.preprod.lendingtree.com',
    stage: 'www.staging.lendingtree.com',
  }[LT_ENV] || 'www.dev.lendingtree.com';

const postData = (propertyObj: { [k: string]: string }): void => {
  const pixelRequestUrl = new URL(`https://${pixelHostname}/pixel/t`);
  fetch(pixelRequestUrl.toString(), {
    method: 'post',
    credentials: 'include',
    keepalive: true,
    body: JSON.stringify(propertyObj),
  }).catch(console.error);
};

export const sendToKafka = (eventName: string) => {
  const propertyObj = buildReferralPixelTrackingProperties(eventName);
  postData(propertyObj);
};

export const sendToKafkaOnMCVIDCallback = (eventName: string) => {
  if (window.Visitor) {
    const visitor: any = window.Visitor.getInstance(
      '2E96401053DB518E0A490D4C@AdobeOrg',
    );
    if (visitor.getMarketingCloudVisitorID()) sendToKafka(eventName);
    else
      visitor.getMarketingCloudVisitorID((id: any) => {
        visitorId = id;
        sendToKafka(eventName);
      });
  } else {
    sendToKafka(eventName);
  }
};

export default () => {
  if (!window.fireLaunchstrapBeginsOnce) {
    sendToKafkaOnMCVIDCallback('launch_strap_begins');
    window.fireLaunchstrapBeginsOnce = true;
  }
};
