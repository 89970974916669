declare const window: any;
window.dataLayer = window.dataLayer || [];

export const gtmTracking = (propertyObj, type) => {
  if (propertyObj && Object.keys(propertyObj).length > 0) {
    if (type === 'page') {
      const name =
        propertyObj.category && propertyObj.name
          ? propertyObj.category + ' ' + propertyObj.name
          : propertyObj.name;
      propertyObj.event = 'Loaded a Page';
      if (name) {
        propertyObj.event = 'Viewed ' + name + ' Page';
      }
    } else {
      propertyObj.event = propertyObj.name || propertyObj.trackEventName || '';
    }
    window.dataLayer.push(propertyObj);
  }
};
