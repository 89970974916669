/**
 * returns an executable function
 *  that will wrap in a try/catch statement
 * */
export default (functionToExec) => () => {
  try {
    functionToExec.apply(this);
  } catch (e) {
    // catch errors and throw in console
    // will continue with other code
    console.error(e);
  }
};
