import { isUndefined } from '../common/is-type';
import { getCookie } from '../common/get-cookie';
import { getData } from '../integrations/lta-data';

// constants and handlers

const mktTrackCookieName = 'MKT_TRACK';
const userOriginationDataCookieName = 'UserOriginationData';

export const getMtaidFromCookieOrQs = () => {
  if (getData('mtaid')) return getData('mtaid');
  const trackingObj: { [k: string]: any } = getCookieObject();
  if (isUndefined(trackingObj)) return getParamFromQs('mtaid');
  return !isUndefined(trackingObj && trackingObj.mtaid)
    ? trackingObj.mtaid
    : '';
};

export const getClickSessionId = () => {
  if (getData('ClickSessionId')) return getData('ClickSessionId');
  const trackingObj: { [k: string]: any } = getCookieObject();
  if (
    isUndefined(trackingObj) &&
    getParamFromQs('mtaid') &&
    getParamFromQs('sessionid')
  )
    return getParamFromQs('sessionid');
  return !isUndefined(trackingObj && trackingObj.ClickSessionId)
    ? trackingObj.ClickSessionId
    : '';
};

export const getTreeAnonymousTransientUid = () => {
  if (getData('TreeAnonymousTransientUid'))
    return getData('TreeAnonymousTransientUid');
  const userOriginationDataObj: { [k: string]: any } = getCookieObject(
    userOriginationDataCookieName,
  );
  if (isUndefined(userOriginationDataObj)) return;
  return !isUndefined(userOriginationDataObj.TreeAnonymousTransientUid)
    ? userOriginationDataObj.TreeAnonymousTransientUid
    : '';
};

export const getCookieObject = (cookieName: string = mktTrackCookieName) => {
  const getCookieByName = getCookie(cookieName);
  if (getCookieByName) {
    const cookieDecoded = decodeURIComponent(getCookieByName);
    if (isUndefined(cookieDecoded)) return;
    return JSON.parse(cookieDecoded);
  }
};

export const getParamFromQs = (param: string) =>
  new URLSearchParams(location.search).get(param) || '';

export const getMktTrackDataFromApi = (
  responseObj: any,
  cookieName: string = mktTrackCookieName,
) => {
  const cookieData = getCookie(cookieName, responseObj);
  if (cookieData) {
    const cookieDecoded = decodeURIComponent(cookieData);
    if (cookieDecoded) {
      return JSON.parse(cookieDecoded);
    }
  }
};
