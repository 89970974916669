export default () => {
    const hostName = window.location.hostname;
    const pathName = window.location.pathname.toLowerCase();

    const gtmContainerMapping = [{
            stringToMatch: hostName,
            stringToCompare: 'offers.',
            gtmContainerId: hostName.includes('magnifymoney.com') ? 'GTM-MXN3F4' : '',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'magnifymoney.com',
            gtmContainerId: 'GTM-MXN3F4',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'depositaccounts.com',
            gtmContainerId: 'GTM-PMML277',
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/app/offers',
            gtmContainerId: hostName.startsWith('business.') ? 'GTM-M87M9C' : '',
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/app/login/callback',
            gtmContainerId: hostName.startsWith('business.') ? 'GTM-M87M9C' : '',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'business.',
            gtmContainerId: 'GTM-KW3CKJQ',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'myoffers.',
            gtmContainerId: 'GTM-M87M9C',
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/offers',
            gtmContainerId: 'GTM-M87M9C',
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/myresults',
            gtmContainerId: 'GTM-M87M9C',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'mc.',
            gtmContainerId: 'GTM-PS7KWZ',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'spring.',
            gtmContainerId: 'GTM-PS7KWZ',
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/form/',
            gtmContainerId: 'GTM-W5DKKXF',
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/forms/',
            gtmContainerId: 'GTM-W5DKKXF',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'mylt.',
            gtmContainerId: 'GTM-PS7KWZ',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'my.',
            gtmContainerId: 'GTM-PS7KWZ',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'equifax.',
            gtmContainerId: 'GTM-PS7KWZ',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'myblock.',
            gtmContainerId: 'GTM-PS7KWZ',
        },
        {
            stringToMatch: hostName,
            stringToCompare: 'lendingtree.com',
            gtmContainerId: hostName.startsWith('www.') && !hostName.includes('comparecards') ? 'GTM-PB2FNQ' : '',
        },
    ];

    let gtmContainerId = '';
    for (const app of gtmContainerMapping) {
        if (app.stringToMatch.includes(app.stringToCompare)) {
            gtmContainerId = app.gtmContainerId;
            if (gtmContainerId) {
                break;
            }
        }
    }

    if (
        gtmContainerId &&
        !window.disablegtm &&
        (!window.google_tag_manager ||
            (window.google_tag_manager && !window.google_tag_manager[gtmContainerId]))
    ) {
        (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            var f = d.getElementsByTagName(s)[0];
            var j = d.createElement(s);
            var dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        }(window, document, 'script', 'dataLayer', gtmContainerId));
    }
    return gtmContainerId;
};