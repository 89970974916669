import launchPath from '../handler/launch-path-handler';
import onTrackingReady from '../common/on-tracking-ready';

// common includes

export default () => {
  onTrackingReady(() => {
    launchPath();
  });
};
